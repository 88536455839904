<template>
    <div>

        <div id="steps" class="container window-overflow frame overflow-auto">
            <div class="menu-formulario text-center">
                <div class="row align-items-start border-shadow-outer centrar">
                    <div id="step1" class="menu-formulario-items item-1 col-2" :class="{ 'active' : step_a === '1' }">
                        <div v-if="step_a === '2' || step_a === '3' || step_a === '4' || (step_a === '5' && !step.step5OK) || (step_a === '1' && step.step1Completed)" class="menu-formulario-img">
                            <a href="#" @click.prevent="goToStep(1)">
                                <img v-if="step_a === '1'" src="@/assets/img/form-menu/01_active.svg" class="" alt="">
                                <img v-else src="@/assets/img/form-menu/01_completed.svg" class="" alt="">
                            </a>
                        </div>
                        <div v-else class="menu-formulario-img">
                            <img v-if="step_a === '1'" src="@/assets/img/form-menu/01_active.svg" class="" alt="">
                            <img v-else src="@/assets/img/form-menu/01_completed.svg" class="" alt="">
                        </div>

                        <div class="menu-formulario-text">
                            <div class="menu-formulario-number">
                                <p v-if="step_a === '2' || step_a === '3' || step_a === '4' || (step_a === '5' && !step.step5OK) || (step_a === '1' && step.step1Completed)" class="number"><a href="#" @click.prevent="goToStep(1)">01</a></p>
                                <p v-else class="number">01</p>
                            </div>
                            <div class="menu-formulario-title">
                                <p v-if="step_a === '2' || step_a === '3' || step_a === '4' || (step_a === '5' && !step.step5OK) || (step_a === '1' && step.step1Completed)" class="title"><a href="#" @click.prevent="goToStep(1)">{{$t('steps.stepOne.title')}}</a></p>
                                <p v-else class="title">{{$t('steps.stepOne.title')}}</p>
                            </div>
                        </div>
                    </div>

                    <div id="step2" class="menu-formulario-items item-2 col-2" :class="{ 'active' : step_a === '2' }">
                        <div v-if="step_a === '2' || step_a === '3' || step_a === '4' || (step_a === '5' && !step.step5OK) || (step_a === '1' && step.step1Completed)" class="menu-formulario-img">
                            <a href="#" @click.prevent="goToStep(2)">
                                <img v-if="step_a === '1'" src="@/assets/img/form-menu/02.svg" class="img-fluid" alt="">
                                <img v-if="step_a === '2'" src="@/assets/img/form-menu/02_active.svg" class="img-fluid" alt="">
                                <img v-if="step_a === '3' || step_a === '4' || step_a === '5' || step_a === '6'" src="@/assets/img/form-menu/02_completed.svg" class="img-fluid" alt="">
                            </a>
                        </div>
                        <div v-else class="menu-formulario-img">
                            <img v-if="step_a === '1'" src="@/assets/img/form-menu/02.svg" class="img-fluid" alt="">
                            <img v-if="step_a === '2'" src="@/assets/img/form-menu/02_active.svg" class="img-fluid" alt="">
                            <img v-if="step_a === '3' || step_a === '4' || step_a === '5' || step_a === '6'" src="@/assets/img/form-menu/02_completed.svg" class="img-fluid" alt="">
                        </div>

                        <div class="menu-formulario-text">
                            <div class="menu-formulario-number">
                                <p class="number" v-if="step_a === '2' || step_a === '3' || step_a === '4' || (step_a === '5' && !step.step5OK) || (step_a === '1' && step.step1Completed)"><a href="#" @click.prevent="goToStep(2)">02</a></p>
                                <p class="number" v-else>02</p>

                            </div>
                            <div class="menu-formulario-title">
                                <p class="title" v-if="step_a === '2' || step_a === '3' || step_a === '4' || (step_a === '5' && !step.step5OK) || (step_a === '1' && step.step1Completed)"><a href="#" @click.prevent="goToStep(2)">{{$t('steps.stepTwo.title')}}</a></p>
                                <p class="title" v-else>{{$t('steps.stepTwo.title')}}</p>
                            </div>
                        </div>
                    </div>

                    <div id="step3" class="menu-formulario-items item-3 col-2" :class="{ 'active' : step_a === '3' }">
                        <div v-if="step_a === '2' || step_a === '3' || step_a === '4' || (step_a === '5' && !step.step5OK) || (step_a === '1' && step.step1Completed)" class="menu-formulario-img">
                            <a href="#" @click.prevent="goToStep(3)">
                                <img v-if="step_a === '1' || step_a === '2'" src="@/assets/img/form-menu/03.svg" class="img-fluid" alt="">
                                <img v-if="step_a === '3'" src="@/assets/img/form-menu/03_active.svg" class="img-fluid" alt="">
                                <img v-if="step_a === '4' || step_a === '5' || step_a === '6'" src="@/assets/img/form-menu/03_completed.svg" class="img-fluid" alt="">
                            </a>
                        </div>
                        <div v-else class="menu-formulario-img">
                            <img v-if="step_a === '1' || step_a === '2'" src="@/assets/img/form-menu/03.svg" class="img-fluid" alt="">
                            <img v-if="step_a === '3'" src="@/assets/img/form-menu/03_active.svg" class="img-fluid" alt="">
                            <img v-if="step_a === '4' || step_a === '5' || step_a === '6'" src="@/assets/img/form-menu/03_completed.svg" class="img-fluid" alt="">
                        </div>

                        <div class="menu-formulario-text">
                            <div class="menu-formulario-number">
                                <p class="number" v-if="step_a === '2' || step_a === '3' || step_a === '4' || (step_a === '5' && !step.step5OK) || (step_a === '1' && step.step1Completed)"><a href="#" @click.prevent="goToStep(3)">03</a></p>
                                <p class="number" v-else>03</p>
                            </div>
                            <div class="menu-formulario-title">
                                <p class="title" v-if="step_a === '2' || step_a === '3' || step_a === '4' || (step_a === '5' && !step.step5OK) || (step_a === '1' && step.step1Completed)"><a href="#" @click.prevent="goToStep(3)">{{$t('steps.stepThree.title')}}</a></p>
                                <p class="title" v-else>{{$t('steps.stepThree.title')}}</p>
                            </div>
                        </div>
                    </div>

                    <div id="step4" class="menu-formulario-items item-4 col-2" :class="{ 'active' : step_a === '4' }">
                        <div v-if="step_a === '2' || step_a === '3' || step_a === '4' || (step_a === '5' && !step.step5OK) || (step_a === '1' && step.step1Completed)" class="menu-formulario-img">
                            <a href="#" @click.prevent="goToStep(4)">
                                <img v-if="step_a === '1' || step_a === '2' || step_a === '3'" src="@/assets/img/form-menu/04.svg" class="img-fluid" alt="">
                                <img v-if="step_a === '4'" src="@/assets/img/form-menu/04_active.svg" class="img-fluid" alt="">
                                <img v-if="step_a === '5' || step_a === '6'" src="@/assets/img/form-menu/04_completed.svg" class="img-fluid" alt="">
                            </a>
                        </div>
                        <div v-else class="menu-formulario-img">
                            <img v-if="step_a === '1' || step_a === '2' || step_a === '3'" src="@/assets/img/form-menu/04.svg" class="img-fluid" alt="">
                            <img v-if="step_a === '4'" src="@/assets/img/form-menu/04_active.svg" class="img-fluid" alt="">
                            <img v-if="step_a === '5' || step_a === '6'" src="@/assets/img/form-menu/04_completed.svg" class="img-fluid" alt="">
                        </div>

                        <div class="menu-formulario-text">
                            <div class="menu-formulario-number">
                                <p class="number" v-if="step_a === '2' || step_a === '3' || step_a === '4' || (step_a === '5' && !step.step5OK) || (step_a === '1' && step.step1Completed)"><a href="#" @click.prevent="goToStep(4)">04</a></p>
                                <p class="number" v-else>04</p>
                            </div>
                            <div class="menu-formulario-title">
                                <p class="title" v-if="step_a === '2' || step_a === '3' || step_a === '4' || (step_a === '5' && !step.step5OK) || (step_a === '1' && step.step1Completed)"><a href="#" @click.prevent="goToStep(4)">{{$t('steps.stepFour.title')}}</a></p>
                                <p class="title" v-else v-html="$t('steps.stepFour.title')"></p>
                            </div>
                        </div>
                    </div>
                    <div id="step5" class="menu-formulario-items item-5 col-2" :class="{ 'active' : step_a === '5' }">
                        <div v-if="((step_a === '2' || step_a === '3' || step_a === '4' || (step_a === '5' && !step.step5OK)) && (step.step4Completed)) || (step_a === '1' && step.step1Completed && step.step4Completed)" class="menu-formulario-img">
                            <a href="#" @click.prevent="goToStep(5)">
                                <img v-if="step_a === '1' || step_a === '2' || step_a === '3' || step_a === '4'" src="@/assets/img/form-menu/05.svg" class="img-fluid" alt="">
                                <img v-if="step_a === '5'" src="@/assets/img/form-menu/05_active.svg" class="img-fluid" alt="">
                                <img v-if="step_a === '6'" src="@/assets/img/form-menu/05_completed.svg" class="img-fluid" alt="">
                            </a>
                        </div>
                        <div v-else class="menu-formulario-img">
                            <img v-if="step_a === '1' || step_a === '2' || step_a === '3' || step_a === '4'" src="@/assets/img/form-menu/05.svg" class="img-fluid" alt="">
                            <img v-if="step_a === '5'" src="@/assets/img/form-menu/05_active.svg" class="img-fluid" alt="">
                            <img v-if="step_a === '6'" src="@/assets/img/form-menu/05_completed.svg" class="img-fluid" alt="">
                        </div>
                        <div class="menu-formulario-text">
                            <div class="menu-formulario-number">
                                <p class="number" v-if="((step_a === '2' || step_a === '3' || step_a === '4' || (step_a === '5' && !step.step5OK)) && (step.step4Completed)) || (step_a === '1' && step.step1Completed && step.step4Completed)"><a href="#" @click.prevent="goToStep(5)">05</a></p>
                                <p class="number" v-else>05</p>
                            </div>
                            <div class="menu-formulario-title">
                                <p class="title" v-if="((step_a === '2' || step_a === '3' || step_a === '4' || (step_a === '5' && !step.step5OK)) && (step.step4Completed)) || (step_a === '1' && step.step1Completed && step.step4Completed)" @click.prevent="goToStep(5)" v-html="$t('steps.stepFive.title_ref')"></p>
                                <p class="title" v-else v-html="$t('steps.stepFive.title')"></p>
                            </div>
                        </div>
                    </div>
<!--                    <div id="step6" class="menu-formulario-items item-6 col-2" :class="{ 'active' : step_a === '6' }">
                        <div class="menu-formulario-img">
                            <img v-if="step_a === '1' || step_a === '2' || step_a === '3' || step_a === '4' || step_a === '5'" src="@/assets/img/form-menu/06.svg" class="img-fluid" alt="">
                            <img v-if="step_a === '6'" src="@/assets/img/form-menu/06_active.svg" class="img-fluid" alt="">
                        </div>
                        <div class="menu-formulario-text">
                            <div class="menu-formulario-number">
                                <p class="number">06</p>
                            </div>
                            <div class="menu-formulario-title">
                                <p class="title">{{$t('steps.stepSix.title')}}</p>
                            </div>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
        <div class="contador-pasos text-center d-block d-md-none">
            <p class="contador-pasos-text">
                {{$t('steps.nav.step')}} <span class="paso-number"> 1 </span> {{$t('steps.nav.of')}} 6
            </p>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'Steps',
        components: {
        },
        props: {
            step_a: String
        },
        data() {
            return {
            };
        },
        computed: {
            ...mapState([
                'step'
            ]),
        },
        created() {
        },
        mounted() {
            document.querySelector('.paso-number').innerHTML = this.step_a;
            for (var i = 1; i < 7; i++) {
                if (parseInt(this.step_a) === i) {
                    document.querySelector(`#steps`).scrollLeft = (document.querySelector(`#step${i}`).offsetWidth * i) - (window.innerWidth / 2)
                }
            }
        },
        methods: {
            goToStep(step_b) {
                if (step_b === 1) {
                    this.$router.push('basic-information');
                    document.querySelector('.paso-number').innerHTML = "1";
                }
                else if (step_b === 2) {
                    this.$router.push('academic-results');
                    document.querySelector('.paso-number').innerHTML = "2";
                }
                else if (step_b === 3) {
                    this.$router.push('video-presentation');
                    document.querySelector('.paso-number').innerHTML = "3";
                }
                else if (step_b === 4) {
                    this.$router.push('recommendations-teamworks');
                    document.querySelector('.paso-number').innerHTML = "4";
                }
                else if (step_b === 5) {
                    this.$router.push('payment-fee');
                    document.querySelector('.paso-number').innerHTML = "5";
                }
                else if (step_b === 6) {
                    document.querySelector('.paso-number').innerHTML = "6";
                }
            }
        }
    };
</script>
<style scoped>
</style>
